export function useGetDateCurrent(): { date: Ref<Date> } {
  const date = ref(new Date());

  let intervalId;

  function scheduleNextMinuteUpdate() {
    const now = new Date();
    const millisecondsTillNextMinute =
      60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    setTimeout(() => {
      date.value = new Date();
      startIntervalUpdate();
    }, millisecondsTillNextMinute);
  }

  function startIntervalUpdate() {
    intervalId = setInterval(() => {
      date.value = new Date();
    }, 60000); // Update every minute
  }

  onMounted(() => {
    scheduleNextMinuteUpdate();
  });

  onUnmounted(() => {
    clearInterval(intervalId); // Clear the interval when the component using the composable is unmounted
  });

  return { date } as { date: Ref<Date> };
}

// function getFormattedTime() {
//   const now = new Date();
//   const hours = String(now.getHours()).padStart(2, "0");
//   const minutes = String(now.getMinutes()).padStart(2, "0");
//   return `${hours}:${minutes}`;
// }

// function getFormattedTime() {
//   const now = new Date();
//   let hours = now.getHours();
//   const minutes = String(now.getMinutes()).padStart(2, "0");
//   const ampm = hours >= 12 ? "pm" : "am";
//   hours = hours % 12;
//   hours = hours ? hours : 12; // Adjust the hour '0' to '12'
//   return `${String(hours).padStart(2, "0")}:${minutes} ${ampm}`;
// }
